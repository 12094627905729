.calculator {
    text-align: center;
}

.im-logo {
    width: 10em;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.title {
    background-image: linear-gradient(
        to right,
        #ffc16a 0%,
        #ffd79f 50%,
        #ffc16a 100%
    ) !important;
}

.ddown {
    background-color: #ffc16a !important;
    color: #141414 !important;
}

.ddown:hover {
    background-color: #997440 !important;
}

.ddown-menu {
    background-color: #ffc16a !important;
    border-color: #141414 !important;
}

.vc {
    text-align: center;
    margin-left: 5em;
    width: 8em;
    height: 10em;
    background-color: #ffc16a !important;
}

.vc-body {
    text-align: center !important;
    font-size: 1.2em !important;
    padding: 0.3em !important;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
}
.img-capacity {
    height: 25px;
    width: 20px;
}
.label-header {
    background-color: white !important;
    margin-bottom: 5pt;
    text-align: center;
    text-decoration: overline solid gray;
    font-weight: bold;
    padding-top: 1.4em;
}
.line {
    border-top: 1pt solid slategray;
    border-image: linear-gradient(to right, #7a756f 0%, #ecac5c 100%) 1 !important;
    margin-left: 10%;
    margin-right: 10%;
}
.submit-button{
    margin-top: 1em;
}